
      import { isBrowserSupported } from "./src/index.ts"

      if (!isBrowserSupported()) {
        const t = document.createElement("template")
        t.innerHTML = /* html */ `
          <div class="tint-banner">
            <img src="/shape.svg"/>
            <p>
              For this browser only beta version is available.
              For better experience use <b>Google Chrome.</b>
            </p>
          </div>
        `
        document.body.prepend(t.content)
      }
    