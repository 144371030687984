
      // import { parse } from "./src/token.ts"
      const parse = (token) => JSON.parse(window.atob(token))

      const token = document.querySelector("tint-vto").getAttribute("token")

      let bg = "url(/bg.webp)"

      if (token) {
        const { brand } = parse(token)
        if (brand) bg = `url(/${brand}/bg.webp), ${bg}`
      }

      document.body.style.backgroundImage = bg
    