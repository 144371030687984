
      !(async () => {
        const widget = document.querySelector("tint-vto")

        await widget.open()

        // await widget.useWebcam().then((wcam) => {
        //   if (!wcam)
        //     fetch("model.webp")
        //       .then((r) => r.blob())
        //       .then((b) => widget.usePhoto(b))
        // })
      })()
    